import { EMAIL_PATTERN } from "./utils";

/* Checking the validity of email. */
export const handleEmailValidate = (isValidated: boolean, email: string) => {
  if (isValidated) {
    // Checking if email is available.
    if (!email) {
      return "Email is required.";
    } else if (!EMAIL_PATTERN.test(email)) {
      // Check the email number is valid.
      return "Please enter a valid email address";
    }
  } else {
    return false;
  }
};
