/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Divider, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { useRouteMatch } from "react-router-dom";
import { completeOrder } from "../../../services/OrderServices";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  getCompleteOrderErrorMessage,
} from "../../../utils/utils";
import { AxiosError } from "axios";
import CardCommon from "../../../common/card/CardCommon";

const useStyles = makeStyles((theme) => ({
  GooglePayWrapper: {
    textAlign: "center",
    marginBottom: "60px",
  },
  text: {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

export interface PaymentSuccessProps {
  paymentData: any;
  setError: any;
  setShowSuccessModal: any;
  setTimer: any;
  setSuccess: any;
  orderInfo: any;
  setOpen: any;
  locationName: any;
}

/**
 * This component handles the Google Pay integration for the payment process.
 * It renders the Google Pay button and handles the payment flow using the Stripe API.
 * It also completes the order and displays success/error messages accordingly.
 */
const GooglePlay: React.FunctionComponent<PaymentSuccessProps> = ({
  paymentData,
  setError,
  setShowSuccessModal,
  setTimer,
  setSuccess,
  orderInfo,
  setOpen,
  locationName,
}) => {
  const { params }: any = useRouteMatch();
  const stripe: any = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  /**
   * This function is responsible for completing the order by making a request to the server
   */
  const processCompleteOrder = async () => {
    try {
      // Send a request to complete the order with the provided parameters
      const res = await completeOrder(params.locationId, params.orderId);
      if (res.status === 200) {
        // If the order completion is successful, show the success modal
        setShowSuccessModal(true);

        // Set a timer to hide the success modal after 5 seconds
        const timeout = setTimeout(() => {
          setShowSuccessModal(false);
          // Redirect the user to the order status page
          window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${params.locationId}/order-status/${params.orderId}`;
        }, 3000);
        setTimer(timeout);
      } else {
        // If there is an error completing the order, set an error message indicating a connection error
        setOpen(true);
        setError(
          "GOOGLE_PAY | Error occurred when completing order. Please check your connection.",
        );
      }
    } catch (error) {
      // If the order completion is successful, show the success modal
      setShowSuccessModal(true);

      // Set a timer to hide the success modal after 5 seconds
      const timeout = setTimeout(() => {
        setShowSuccessModal(false);
        // Redirect the user to the order status page
        window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${params.locationId}/order-status/${params.orderId}`;
      }, 3000);
      setTimer(timeout);
    }
  };

  /**
   * This useEffect hook sets up the payment request object when the stripe object
   * and payment data are available. It calculates the amount, creates the payment
   * request with specific configuration options, and checks if the user's
   * device/browser supports the payment request. If supported, it sets the
   * paymentRequest state with the created payment request object.
   */
  useEffect(() => {
    // If there is no payment data, return and do nothing
    if (!paymentData) return;

    // Check if the Stripe object is available
    if (stripe) {
      // Calculate the formatted and rounded amount for the payment request
      const formattedAmount = parseFloat(paymentData?.amount) * 100;
      const roundedAmount = Math.round(formattedAmount);
      // Create a payment request object using the Stripe paymentRequest method
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Payment Amount",
          amount: roundedAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check if the payment request can be made by the user's device/browser
      pr.canMakePayment().then((result: any) => {
        if (result) {
          // If the user's device/browser supports the payment request, set the paymentRequest state
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, paymentData]);

  /**
   * This useEffect block sets up an event listener for the "paymentmethod" event on the paymentRequest object.
   * When a payment method is selected by the user, it confirms the PaymentIntent using Stripe's confirmCardPayment method.
   * It handles potential errors and updates the UI accordingly, showing success or failure messages.
   * If the PaymentIntent requires additional actions, it lets Stripe.js handle the flow.
   * If the payment is successful, it triggers the processCompleteOrder function to complete the order.
   */
  useEffect(() => {
    // Check if payment request object or payment data is not available, then return
    if (!paymentRequest) return;
    if (!paymentData) return;

    // Set up the event listener for the "paymentmethod" event
    paymentRequest.on("paymentmethod", async (ev: any) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          paymentData?.secret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        );
      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
        setOpen(true);
        if (confirmError?.message) {
          return setError(confirmError?.message);
        }
        setError(
          "Sorry! We couldn't process your payment. Please try again or try different payment method",
        );
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11" instead
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(
            paymentData?.secret,
          );
          if (error) {
            // Handle the specific error message if available, otherwise show a generic error message
            setOpen(true);
            if (error?.message) {
              return setError(error?.message);
            }
            setError(
              "Sorry! We couldn't process your payment. Please try again or try different payment method",
            );
            // The payment failed -- ask your customer for a new payment method.
          } else {
            // The payment has succeeded.
            setSuccess("Payment successfully processed");
            processCompleteOrder();
          }
        } else {
          // The payment has succeeded.
          setSuccess("Payment successfully processed");
          processCompleteOrder();
        }
      }
    });
  }, [paymentRequest, paymentData, stripe]);

  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.text} style={{ fontWeight: "bold" }}>
        Secure Payment
      </Typography>
      <Typography>Enter your payment details to complete</Typography>
      <Typography style={{ marginBottom: "60px" }}>your purchase</Typography>
      {paymentRequest && (
        <div className={classes.GooglePayWrapper}>
          <CardCommon>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                style: {
                  paymentRequestButton: {
                    type: "default",
                    // One of 'default', 'book', 'buy', or 'donate'
                    // Defaults to 'default'

                    // theme: theme.palette.type,
                    // One of 'dark', 'light', or 'light-outline'
                    // Defaults to 'dark'

                    height: "48px",
                    // Defaults to '40px'. The width is always '100%'.
                  },
                },
              }}
            />
          </CardCommon>
          <div style={{ marginTop: "60px" }}></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Divider style={{ flex: 1, marginRight: "10px" }} />
            <Typography variant="subtitle2" component="div">
              Or pay with card
            </Typography>
            <Divider style={{ flex: 1, marginLeft: "10px" }} />
          </div>
        </div>
      )}
    </>
  );
};

export default GooglePlay;
