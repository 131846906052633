import { makeStyles } from "@material-ui/core";
import * as React from "react";

export interface PaymentCompleteProps {
  children: any;
}

const useStyles = makeStyles((theme: any) => ({
  ContentContainerWrapper: {
    minHeight: 480,
    [theme.breakpoints.up("md")]: {
      minHeight: 600,
    },
    overflowY: "auto",
    overflowX: "hidden",
    // overflowY: (props) => (props.overFlowUnset ? "unset" : "auto"),
    // overflowX: (props) => (props.overFlowUnset ? "unset" : "hidden"),
  },
}));

/**
 * This component renders a container for content with customizable overflow behavior.
 */
const ContentContainer: React.FunctionComponent<PaymentCompleteProps> = ({
  children,
}) => {
  const classes = useStyles();

  return <div className={classes.ContentContainerWrapper}>{children}</div>;
};

export default ContentContainer;
