import React from "react";
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import CardCommon from "../../../common/card/CardCommon";
import logo from "../../../assets/images/logo.png";
import eatPrestoLogo from "../../../assets/images/eatPrestoLogo.png";
import stripe_badge from "../../../assets/images/stripe_badge.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  text: {
    color: "#94af9f",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  textSecond: {
    color: "#618b72",
    fontWeight: "bold",
    fontSize: "28px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
    },
  },
}));

// Define the props interface for the PaymentDetails component
interface LocationDetailsProps {
  paymentAmount: any;
}

/**
 * This component displays the location name and payment amount.
 */
const PaymentDetails: React.FunctionComponent<LocationDetailsProps> = ({
  paymentAmount,
}) => {
  const classes = useStyles();

  // Render the location name and payment amount using Material-UI components
  return (
    <div className={classes.root}>
      <Typography className={classes.text}>Total to Pay: </Typography>
      {paymentAmount && (
        <Typography
          className={classes.textSecond}
        >{`£${paymentAmount}`}</Typography>
      )}
    </div>
  );
};

export default PaymentDetails;
