import React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Button, Tooltip, createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: "#F4F5F7",
      marginBottom: "20px",
      borderRadius: "10px",
    },
  }),
);

export interface PaymentCompleteProps {
  setIsBack: any;
  isBack: any;
  entityId: any;
}

/**
 * This component displays button with an arrow icon to handle the back functionality.
 */
const BackBar: React.FunctionComponent<PaymentCompleteProps> = ({
  setIsBack,
  isBack,
  entityId,
}) => {
  const classes = useStyles();
  // Render a button with an arrow icon to handle the back functionality
  return (
    <div className={classes.root}>
      <Tooltip title="Change user email">
        {isBack ? (
          <Button onClick={() => setIsBack(false)} disabled={!entityId}>
            <ArrowForwardIosIcon />
          </Button>
        ) : (
          <Button onClick={() => setIsBack(true)} disabled={!entityId}>
            <ArrowBackIosIcon />
          </Button>
        )}
      </Tooltip>
    </div>
  );
};

export default BackBar;
