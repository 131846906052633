import React from "react";
import ButtonCommon from "../../../common/buttons/ButtonCommon";
import ValidationMessage from "../../../common/validation/ValidationMessage";
import TextfieldCommon from "../../../common/tesxfields/TextfieldCommon";
import { handleEmailValidate } from "../../../utils/HandleEmailValidate";
import _ from "lodash";
import { Theme, Typography, makeStyles } from "@material-ui/core";

interface SubmitEmailProps {
  email: any;
  isValidated: any;
  handleChangeEmail: any;
  handleSubmit: any;
  isLoadingButton: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: "8px",
    marginBottom: "20px",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

/**
 * This component displays an email input field and an email submit button.
 *
 * Props:
 * - email: The value of the email input field.
 * - isValidated: The validation status of the email input.
 * - handleChangeEmail: A function to handle changes in the email input field.
 * - handleSubmit: A function to handle the submission of the email.
 * - isLoadingButton: A boolean indicating whether the button is in a loading state.
 *
 * @returns {React.ReactNode} The SubmitEmail component.
 */
const SubmitEmail: React.FunctionComponent<SubmitEmailProps> = ({
  email,
  isValidated,
  handleChangeEmail,
  handleSubmit,
  isLoadingButton,
}) => {

  const classes = useStyles();
  return (
    <>
      <Typography className={classes.root}>
        We' ll send your receipt to the email address you provide. Please enter
        it below
      </Typography>
      <Typography
        variant="body1"
        style={{ marginLeft: "8px", fontWeight: "bold" }}
      >
        Email
      </Typography>
      <TextfieldCommon
        id="email"
        name="email"
        // label="Enter your email for payment receipt"
        type="text"
        autoComplete="off"
        error={!_.isEmpty(handleEmailValidate(isValidated, email))}
        value={email}
        onChange={handleChangeEmail}
      />
      <div style={{ marginLeft: "2px" }}>
        <ValidationMessage message={handleEmailValidate(isValidated, email)} />
      </div>
      <ButtonCommon
        disabled={!_.isEmpty(handleEmailValidate(isValidated, email))}
        isLoadingPage={isLoadingButton}
        style={{
          fontSize: 10,
          marginTop: "20px",
          marginBottom: "20px",
          width: "100%",
          fontWeight: "bold",
          textTransform: "none",
        }}
        variant="contained"
        onClick={handleSubmit}
      >
        <Typography style={{ color: "white" }}>Continue to Payment</Typography>
      </ButtonCommon>
    </>
  );
};

export default SubmitEmail;
