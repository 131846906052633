import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { Card } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: `1px solid #DFE1E6`,
      overflowY: "auto",
      boxShadow: "none",
    },
  }),
);

export interface CardCommonProps {
  children: any;
  color?: any;
  backgroundColor?: any;
  style?: any;
}

/**
 * This component renders a common card with customizable styles.
 */
const CardCommon: React.FunctionComponent<CardCommonProps> = ({
  children,
  color,
  backgroundColor,
  style,
}) => {
  const classes = useStyles({ color, backgroundColor });
  return (
    <Card className={classes.root} style={style}>
      {children}
    </Card>
  );
};

export default CardCommon;
