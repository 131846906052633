import React from "react";
import {
  Grid,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import CardCommon from "../../../common/card/CardCommon";
import logo from "../../../assets/images/logo.png";
import eatPrestoLogo from "../../../assets/images/eatPrestoLogo.png";
import stripe_badge from "../../../assets/images/stripe_badge.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "0px 40px 40px 40px",
    [theme.breakpoints.up("md")]: {
      margin: "0px",
    },
  },
  locationStyle: {
    fontWeight: "bold",
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  addressStyle: {
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
  },
  imgStyle: {
    height: "36px",
    [theme.breakpoints.down("sm")]: {
      height: "32px",
    },
  },
}));

// Define the props interface for the LocationDetails component
interface LocationDetailsProps {
  locationName: any;
  paymentAmount: any;
  contactNo: any;
  address: any;
}

/**
 * This component displays the location name and payment amount.
 */
const LocationDetails: React.FunctionComponent<LocationDetailsProps> = ({
  locationName,
  paymentAmount,
  contactNo,
  address,
}) => {
  const classes = useStyles();

  // Render the location name and payment amount using Material-UI components
  return (
    <div className={classes.root}>
      <Typography className={classes.locationStyle}>{locationName}</Typography>
      <Typography className={classes.addressStyle}>{address}</Typography>
      <Typography>{contactNo}</Typography>
      <div style={{ marginTop: "20px" }}>
        <CardCommon>
          <div
            style={{
              display: "flex",
              marginLeft: "3%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <img className={classes.imgStyle} src={logo} />
            <img
              className={classes.imgStyle}
              style={{ marginLeft: "16px" }}
              src={eatPrestoLogo}
            />
          </div>
          <div style={{ backgroundColor: "#f8f7f7" }}>
            <Grid container>
              <Grid item xs={10} sm={6} md={8} lg={6}>
                <img
                  style={{
                    width: "100%",
                    height: "auto", // Ensure the image maintains its aspect ratio
                    display: "block",
                  }}
                  src={stripe_badge}
                />
              </Grid>
            </Grid>
          </div>
        </CardCommon>
      </div>
    </div>
  );
};

export default LocationDetails;
