import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useRouteMatch } from "react-router-dom";
import { fetchAllOrderInfoNotShortId } from "../../services/OrderServices";
import { AxiosError } from "axios";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../utils/utils";
import { convertDateTimeFormat } from "../../utils/ConvertDateTimeFormat";
import { fetchAllLocationInfo } from "../../services/LocationServices";
import _ from "lodash";
import AlertDialog from "../../common/dialogs/AlertDialog";
import success from "../../assets/images/success.png";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "Center",
    justifyContent: "center",
  },
  gridStyle: {
    display: "flex",
    justifyContent: "center",
  },
  titleStyle: {
    fontWeight: "bold",
  },
  bodyStyle: {
    marginLeft: "12px",
  },
  text: {
    color: "#94af9f",
    fontSize: "20px",
    display: "flex",
    justifyContent: "center",
  },
  textSecond: {
    color: "#618b72",
    fontWeight: "bold",
    fontSize: "40px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "40px",
    // [theme.breakpoints.down("sm")]: {
    //   fontSize: "24px",
    // },
  },
}));

// Define the type/interface for order information
interface OrderInfo {
  status: string;
  dueTime: string;
  address: string;
  contactNo: string;
  paymentAmount: string;
  location: string;
  id: string;
  locationId: string;
}

/**
 * OrderStatus component displays the status and details of an order.
 * It retrieves order information from a cookie and renders it using Material-UI components.
 */
const OrderStatus: React.FunctionComponent = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentAmount, setPaymentAmount] = useState("0.00");
  const { params }: any = useRouteMatch();

  /**
   * Retrieves the order information using orderId and locationId.
   * This function is used when shortId is not available.
   */
  const handleGetOrderInfoNotUsingShortId = async (
  ) => {
    // Retrieve the orderId and locationId from the match params
    const { orderId, locationId } = params;
    try {
      // Fetch the order information using the locationId and orderId
      const res = await fetchAllOrderInfoNotShortId(locationId, orderId);
      // Destructure properties from the response object
      const { status, paymentAmount } =
        res.data.data;


      if (status === "started" || status === "checkout") {
        return (window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${locationId}/order/${orderId}`);
      } else {
      setIsLoading(false);
        return setPaymentAmount(`£${parseFloat(paymentAmount).toFixed(2)}`)
      }
      // Update the state with the order details array
    } catch (error) {
      setOpen(true);
      setIsLoading(false);
      const err: any = error as AxiosError;
      if (err?.response?.data?.message) {
        // Set the alert message based on the error response message
        return setAlertMessage(err?.response?.data?.message);
      }
      // Set a default error message if no custom error message is available
      setAlertMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    handleGetOrderInfoNotUsingShortId();
  }, []);

  const handleClose = (e: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  // Render the order status and details using Material-UI components
  return (
    <>
      <AlertDialog
        dialogTitle="Error"
        variant="error"
        open={open}
        handleClose={handleClose}
        handleCancel={handleClose}
      >
        <Typography variant="subtitle2" style={{ margin: "16px 0px" }}>
          {alertMessage}
        </Typography>
      </AlertDialog>
      {!isLoading ? (
        !alertMessage && (
          <div className={classes.root}>
            <div>
              <Typography className={classes.text}>Total Paid</Typography>
              <Typography className={classes.textSecond}>
                {paymentAmount}
              </Typography>
              <Typography align="center">
                <img src={success} />{" "}
              </Typography>
              <Typography
                align="center"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  marginTop: "4px",
                }}
              >
                Payment Successful!
              </Typography>
              <Typography style={{ fontSize: "12px" }} align="center">
                Thank you for your purchase. We have successfully received
              </Typography>
              <Typography style={{ fontSize: "12px" }} align="center">
                your payment. A confirmation email with your transaction
              </Typography>
              <Typography style={{ fontSize: "12px" }} align="center">
                details has been sent to your email address.
              </Typography>
            </div>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
    </>
  );
};

export default OrderStatus;
