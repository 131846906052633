import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      borderRadius: "10px",
      backgroundColor: "#252526",
      "&:hover": {
        backgroundColor: "#252526",
      },
      color: "white",
      height: "48px"
    },
  }),
);

export interface ButtonCommonProps {
  onClick?: any;
  color?: any;
  type?: any;
  [propName: string]: any;
  isLoadingPage?: any;
}

/**
 * This component renders a button with an optional loading indicator.
 */
const ButtonCommon: React.FunctionComponent<ButtonCommonProps> = ({
  onClick,
  color = "yellow",
  type,
  children,
  isLoadingPage,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles({ color });

  // Handles the button click event
  const handleClick = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      className={classes.root}
      disableElevation
      onClick={handleClick}
      type={type}
      size={"large"}
      {...props}
    >
      {/* Renders a loading indicator if isLoading or isLoadingPage is true */}
      {isLoading || isLoadingPage ? (
        <CircularProgress color="inherit" style={{ marginLeft: 4 }} size={20} />
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonCommon;
