import axiosClient from "./client";

const ORDER_INFO_GET_ALL = (shortId) =>
  `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/short-link/order/${shortId}`;

  const ORDER_INFO_UPDATE_ALL = (locationId, orderId) =>
  `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/pos-order/${orderId}/confirm`;

  const ORDER_COMPLETE_UPDATE_ALL = (locationId, orderId) =>
  `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/pos-order/${orderId}/complete`;

  const ORDER_INFO_GET_ALL_NOT_SHORT_ID = (locationId, orderId) =>
  `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/order/${orderId}`;

  const ORDER_CREATE_ALL = (locationId) =>
  `${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public-auth/location/${locationId}/order`;

  export const fetchCreateOrderInfo = (locationId, data) => {
    return axiosClient({
      method: "GET",
      url: ORDER_CREATE_ALL(locationId),
      data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  export const fetchAllOrderInfo = (shortId) => {
    return axiosClient({
      method: "GET",
      url: ORDER_INFO_GET_ALL(shortId),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  export const fetchAllOrderInfoNotShortId = (locationId, orderId) => {
    return axiosClient({
      method: "GET",
      url: ORDER_INFO_GET_ALL_NOT_SHORT_ID(locationId, orderId),
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  export const updateAllOrderInfo = (locationId, orderId, data) =>
  axiosClient({
    url: ORDER_INFO_UPDATE_ALL(locationId, orderId),
    method: "POST",
    data,
  });

  export const completeOrder = (locationId, orderId, data) =>
  axiosClient({
    url: ORDER_COMPLETE_UPDATE_ALL(locationId, orderId),
    method: "POST",
    data: {},
  });