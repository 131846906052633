import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

export interface AlertDialogCommonProps {
  variant: any;
  dialogTitle: any;
  open?: any;
  handleClose?: any;
  handleSubmit?: any;
  submitLabel?: any;
  handleCancel?: any;
  cancelLabel?: any;
  children?: any;
  hidden?: any;
}

/**
 * This component renders an alert dialog with customizable content and actions.
 */
const AlertDialog: React.FunctionComponent<AlertDialogCommonProps> = ({
  variant = "success", // variant= "success" | "error"
  dialogTitle,
  open,
  handleClose,
  handleSubmit,
  submitLabel = "OK",
  handleCancel,
  cancelLabel = "OK",
  children,
  hidden = false,
}) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          color: "white",
          backgroundColor:
            variant === "error"
              ? theme.palette.error.dark
              : theme.palette.success.dark,
          padding: "8px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography style={{color: "white"}} variant="h6">{dialogTitle}</Typography>
          {!hidden && (
              <CancelIcon onClick={handleClose} style={{ color: "white", cursor: "pointer" }} />
          )}
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {handleSubmit && (
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="secondary"
          >
            {submitLabel}
          </Button>
        )}
        {handleCancel && (
          <Button
            onClick={handleCancel}
            variant="contained"
            color="secondary"
          >
            {cancelLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
