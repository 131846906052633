import axiosClient from "./client";

const PAYMENT_INFO_GET_ALL = (locationId, chargeID) =>
`${process.env.REACT_APP_API_ENDPOINT_EATPRESTO}/public/location/${locationId}/pos-order/outcome/${chargeID}`;

export const fetchPaymentErrorInfo = (locationId, chargeID) => {
  return axiosClient({
    method: "GET",
    url: PAYMENT_INFO_GET_ALL(locationId, chargeID),
    headers: {
      "Content-Type": "application/json",
    },
  });
};