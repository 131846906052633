import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textAlign: "start",
      color: "red",
    },
  }),
);

export interface DialogCommonProps {
  message: any;
}

/* This component show the error message */
const ValidationMessage: React.FunctionComponent<DialogCommonProps> = ({
  message,
}) => {
  const classes = useStyles();

  return <div className={classes.root}>{message}</div>;
};

export default ValidationMessage;
