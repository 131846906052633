import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";

export interface PaymentSuccessProps {
  paymentData: any;
  setShowSuccessModal: any;
  setTimer: any;
  timer: any;
  orderInfo: any;
  locationName: any
}

/**
 * This component handles the payment section, including loading the Stripe library
 * and rendering the CheckoutForm component within the Elements wrapper.
 *
 * Props:
 * - paymentData: The payment data object containing Stripe configuration.
 * - setShowSuccessModal: A function to control the display of the success modal.
 * - setTimer: A function to set the timer for the success modal.
 * - timer: The timer for the success modal.
 * - orderInfo: Additional information related to the order.
 *
 * @returns {React.ReactNode} The PaymentSection component.
 */
const PaymentSection: React.FunctionComponent<PaymentSuccessProps> = ({
  paymentData,
  setShowSuccessModal,
  setTimer,
  timer,
  orderInfo,
  locationName
}) => {
  const [stripePromiseObj, setStripePromiseObj] = useState(null);

  /**
   * This useEffect hook is responsible for loading the Stripe library based on the provided paymentData.
   * It checks if the paymentData public key is available and then asynchronously loads the Stripe library
   * with the appropriate configuration based on the payment gateway provider.
   * The effect is triggered whenever the paymentData changes.
   */
  useEffect(() => {
    // Check if the paymentData public key is available
    if (!paymentData.publicKey) return;

    /**
     * Loads the Stripe library with the provided public key and account information.
     */
    const loadStripeDefaultAsync = async () => {
      const stripeProm: any = await loadStripe(paymentData.publicKey, {
        apiVersion: "2020-08-27",
        stripeAccount: paymentData.entityId,
      });
      setStripePromiseObj(stripeProm);
    };

    /**
     * Loads the Stripe library with the provided public key (for Express mode).
     */
    const loadStripeExpressAsync = async () => {
      const stripeProm: any = await loadStripe(paymentData.publicKey);
      setStripePromiseObj(stripeProm);
    };

    // Determine the payment gateway provider and load the appropriate Stripe configuration.
    if (paymentData.paymentGatewayProvider === "stripe") {
      loadStripeDefaultAsync();
    } else if (paymentData.paymentGatewayProvider === "express") {
      loadStripeExpressAsync();
    }
  }, [paymentData]);

  return (
    <Elements stripe={stripePromiseObj}>
      <CheckoutForm
        paymentData={paymentData}
        setShowSuccessModal={setShowSuccessModal}
        setTimer={setTimer}
        timer={timer}
        orderInfo={orderInfo}
        locationName={locationName}
      />
    </Elements>
  );
};

export default PaymentSection;
