import * as React from "react";
import AlertDialog from "./AlertDialog";
import { useRouteMatch } from "react-router-dom";
import DialogContentText from "@material-ui/core/DialogContentText";

export interface OrderSuccessAlertDialogProps {
  open: any;
  handleClose: any;
}

/**
 * This component renders an alert dialog for a successful order placement.
 * It provides an option to redirect to the order status page.
 */
const OrderSuccessAlertDialog: React.FunctionComponent<
  OrderSuccessAlertDialogProps
> = ({ open, handleClose }) => {
  const { params }: any = useRouteMatch();

  // Handles the redirection to the order status page
  const handleRedirect = () => {
    window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${params.locationId}/order-status/${params.orderId}`;
  };

  return (
    <AlertDialog
      variant="success"
      dialogTitle="Order placed successfully"
      open={open}
      handleClose={handleClose}
      handleSubmit={handleRedirect}
    >
      <DialogContentText id="alert-dialog-description">
        You will now be redirected to order status page. If not please use the
        ORDER STATUS PAGE button to manually navigate to order status page.
      </DialogContentText>
    </AlertDialog>
  );
};

export default OrderSuccessAlertDialog;
