import * as React from "react";
import { Theme, Typography, makeStyles } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AlertDialog from "./AlertDialog";

const useStyles = makeStyles((theme: Theme) => ({
  PaymentErrorAlertDialogWrapper: {},
  alert: {
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(94, 38, 39)",
    color: theme.palette.error.contrastText,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
  },
}));

/**
 * Renders an alert dialog for payment errors.
 * It displays an error message, sub-error message (optional), and additional alert (optional).
 */
const AlertReversedAfterApproval = () => {
  const classes = useStyles();

  return (
    <div className={classes.alert}>
      <ErrorOutlineIcon style={{ fontSize: 72 }} />
      <span style={{ margin: 4 }} />
      <div>
        <Typography variant="subtitle1">IMPORTANT:</Typography>
        <Typography variant="subtitle2">
          This charge may temporarily appear as pending on your statement before
          being removed.
        </Typography>
      </div>
    </div>
  );
};

export interface PaymentErrorAlertDialogProps {
  error: any;
  subError: any;
  open?: any;
  onClose?: any;
  isReversedAfterApproval?: any;
}

const PaymentErrorAlertDialog: React.FunctionComponent<
  PaymentErrorAlertDialogProps
> = ({ error, subError, open, onClose, isReversedAfterApproval }) => {
  // Handles the dialog close event
  const handleClose = (e: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    onClose();
  };
  return (
    <AlertDialog
      variant="error"
      dialogTitle="Payment not authorized"
      open={open}
      handleClose={handleClose}
      handleCancel={handleClose}
    >
      <Typography variant="subtitle2" style={{ margin: "16px 0px" }}>
        {error}
      </Typography>
      {subError && (
        <Typography style={{ margin: "16px 0px" }} variant="subtitle2">
          {subError}
        </Typography>
      )}
      {isReversedAfterApproval && <AlertReversedAfterApproval />}
    </AlertDialog>
  );
};

export default PaymentErrorAlertDialog;
