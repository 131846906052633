import React from "react";
import CardCommon from "../../../common/card/CardCommon";
import BackBar from "..//BackBar";
import {
  Divider,
  Grid,
  Theme,
  Typography,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import PaymentSection from "../PaymentSection";
import _ from "lodash";
import LocationDetails from "../LocationDetails";
import WithLoading from "../../../utils/WithLoading";
import SubmitEmail from "../SubmitEmail";
import PaymentDetails from "../PaymentDetails";

const useStyles = makeStyles((theme: Theme) => ({
  rootTab: { margin: "10px 40px" },
  root: { margin: "40px 40px 10px 40px" },
  rootTabs: { margin: "30px 40px 10px 40px" },
  locationStyle: {
    height: "88vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

// Define the props interface for the Loading component
interface LoadingProps {
  locationName: any;
  paymentAmount: any;
  setIsBack: any;
  isBack: any;
  paymentData: any;
  email: any;
  isValidated: any;
  handleChangeEmail: any;
  handleSubmit: any;
  setShowSuccessModal: any;
  setTimer: any;
  timer: any;
  orderInfo: any;
  isLoadingButton: any;
  isLoading: any;
  contactNo: any;
  address: any;
}

/**
 * Define the Loading component
 */
const Loading: React.FunctionComponent<LoadingProps> = ({
  locationName,
  paymentAmount,
  setIsBack,
  isBack,
  paymentData,
  email,
  isValidated,
  handleChangeEmail,
  handleSubmit,
  setShowSuccessModal,
  setTimer,
  timer,
  orderInfo,
  isLoadingButton,
  isLoading,
  contactNo,
  address,
}) => {
  // Use the useMediaQuery hook to determine if the screen size is small
  const tabScreen = useMediaQuery("(max-width: 959px)");
  const classes = useStyles();
  // Render the email field, submit email button, payment section and location details using Material-UI components
  return (
    <>
      {tabScreen ? (
        paymentData.entityId ? (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ margin: "40px 40px 10px 40px" }}>
                {!isBack && (
                  <BackBar
                    setIsBack={setIsBack}
                    isBack={isBack}
                    entityId={paymentData.entityId}
                  />
                )}
                <PaymentDetails paymentAmount={paymentAmount} />
              </div>
              <div className={classes.rootTab}>
                {/* Render the submit email form if in back or no payment data */}
                {(isBack || !paymentData) && (
                  <SubmitEmail
                    email={email}
                    isValidated={isValidated}
                    handleChangeEmail={handleChangeEmail}
                    handleSubmit={handleSubmit}
                    isLoadingButton={isLoadingButton}
                  />
                )}
                {/* Render the payment section if payment data is available */}
                {!isBack && paymentData && (
                  <>
                    <PaymentSection
                      paymentData={paymentData}
                      setShowSuccessModal={setShowSuccessModal}
                      setTimer={setTimer}
                      timer={timer}
                      orderInfo={orderInfo}
                      locationName={locationName}
                    />
                  </>
                )}
              </div>

              <div>
                <LocationDetails
                  locationName={locationName}
                  paymentAmount={paymentAmount}
                  contactNo={contactNo}
                  address={address}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div style={{ margin: "40px 40px 10px 40px" }}>
                <PaymentDetails paymentAmount={paymentAmount} />
              </div>
              <div className={classes.rootTab}>
                {/* Render the submit email form if in back or no payment data */}
                <SubmitEmail
                  email={email}
                  isValidated={isValidated}
                  handleChangeEmail={handleChangeEmail}
                  handleSubmit={handleSubmit}
                  isLoadingButton={isLoadingButton}
                />
              </div>

              <div>
                <LocationDetails
                  locationName={locationName}
                  paymentAmount={paymentAmount}
                  contactNo={contactNo}
                  address={address}
                />
              </div>
            </Grid>
          </Grid>
        )
      ) : paymentData.entityId ? (
        <Grid container>
          <Grid item xs={12} md={6}>
            <div style={{ margin: "30px 40px 0px 40px" }}>
              {!tabScreen ? (
                <div className={classes.locationStyle}>
                  <PaymentDetails paymentAmount={paymentAmount} />
                  <LocationDetails
                    locationName={locationName}
                    paymentAmount={paymentAmount}
                    contactNo={contactNo}
                    address={address}
                  />
                </div>
              ) : (
                <PaymentDetails paymentAmount={paymentAmount} />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardCommon style={{ height: "99.7vh" }}>
              <div className={classes.root}>
                {!isBack && (
                  <BackBar
                    setIsBack={setIsBack}
                    isBack={isBack}
                    entityId={paymentData.entityId}
                  />
                )}
                {/* Render the submit email form if in back or no payment data */}
                {(isBack || !paymentData) && (
                  <SubmitEmail
                    email={email}
                    isValidated={isValidated}
                    handleChangeEmail={handleChangeEmail}
                    handleSubmit={handleSubmit}
                    isLoadingButton={isLoadingButton}
                  />
                )}
                {/* Render the payment section if payment data is available */}
                {!isBack && paymentData && (
                  <PaymentSection
                    paymentData={paymentData}
                    setShowSuccessModal={setShowSuccessModal}
                    setTimer={setTimer}
                    timer={timer}
                    orderInfo={orderInfo}
                    locationName={locationName}
                  />
                )}
              </div>
            </CardCommon>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} md={6}>
            <div style={{ margin: "30px 40px 0px 40px" }}>
              <div className={classes.locationStyle}>
                <PaymentDetails paymentAmount={paymentAmount} />
                <LocationDetails
                  locationName={locationName}
                  paymentAmount={paymentAmount}
                  contactNo={contactNo}
                  address={address}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <CardCommon style={{ height: "99.7vh" }}>
              <div className={classes.root}>
                {/* Render the submit email form if in back or no payment data */}
                <SubmitEmail
                  email={email}
                  isValidated={isValidated}
                  handleChangeEmail={handleChangeEmail}
                  handleSubmit={handleSubmit}
                  isLoadingButton={isLoadingButton}
                />
              </div>
            </CardCommon>
          </Grid>
        </Grid>
      )}
    </>
  );
};

// Export the Loading component wrapped with the WithLoading higher-order component
export default WithLoading(Loading);
