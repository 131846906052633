import { TextField, makeStyles } from "@material-ui/core";
import * as React from "react";

export interface PaymentCompleteProps {
  inputProps: any;
}

const useStyles = makeStyles((theme: any) => ({
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    border: "none",
    [`& fieldset`]: {
      border: "none",
      cursor: "pointer",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  root: {
    "& .MuiInputBase-root": {
      margin: theme.spacing(1, 0),
      color: theme.palette.text.primaryTextColor,
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.text.primaryTextColor,
    },
  },
}));

/**
 * This component renders a customized text field.
 * This component used to input the card details
 */
const TextFieldDefault: React.FunctionComponent<PaymentCompleteProps> = ({
  inputProps,
  ...props
}) => {
  const classes = useStyles();
  const inputStyle = { WebkitBoxShadow: "0 0 0 1000px transparent inset" };
  return (
    <TextField
      id="card"
      required
      fullWidth
      color={"secondary"}
      className={classes.textField}
      size="small"
      variant="outlined"
      {...props}
      InputProps={{ style: inputStyle, maxLength: 100, ...inputProps }}
    />
  );
};

export default TextFieldDefault;
