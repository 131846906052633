import axiosClient from "./client";

const LOCATION_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_ENDPOINT_LOCATION}/public/location/${locationId}`;

  export const fetchAllLocationInfo = (locationId) => {
    return axiosClient({
      method: "GET",
      url: LOCATION_INFO_GET_ALL(locationId),
      headers: {
        "X-Role-Location": locationId,
        "Content-Type": "application/json",
      },
    });
  };