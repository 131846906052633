import React, { useEffect, useState } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import CardSection from "../CardSection";
import ContentContainer from "../../../containers/ContentContainer";
import {
  FormControl,
  Grid,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ButtonCommon from "../../../common/buttons/ButtonCommon";
import { useForm } from "react-hook-form";
import { useRouteMatch } from "react-router-dom";
import { fetchPaymentErrorInfo } from "../../../services/PaymentService";
import {
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  ERROR_UNEXPECTED_CHECK_CONNECTION,
  PaymentRules,
} from "../../../utils/utils";
import _ from "lodash";
import { completeOrder } from "../../../services/OrderServices";
import { AxiosError } from "axios";
import PaymentErrorAlertDialog from "../../../common/dialogs/PaymentErrorAlertDialog";
import GooglePlay from "../GooglePlay";
import ErrorBoundary from "../../../common/errors/ErrorBoundary";
import { convertDateTimeFormat } from "../../../utils/ConvertDateTimeFormat";
import AlertDialog from "../../../common/dialogs/AlertDialog";

export interface CheckoutFormProps {
  paymentData: any;
  setShowSuccessModal: any;
  setTimer: any;
  timer: any;
  orderInfo: any;
  locationName: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
}));

const CheckoutForm: React.FunctionComponent<CheckoutFormProps> = ({
  paymentData,
  setShowSuccessModal,
  setTimer,
  timer,
  orderInfo,
  locationName,
}) => {
  const [error, setError] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [stripeError, setStripeError] = useState<any>("");
  const [success, setSuccess] = useState("");
  const [isReversedAfterApproval, setIsReversedAfterApproval] = useState(false);
  const { trigger, handleSubmit: handleFormSubmit } = useForm();
  const [paymentInfo, setPaymentInfo] = useState({
    postalCode: "",
  });

  const { params }: any = useRouteMatch();
  const stripe = useStripe();
  const elements = useElements();
  // Clear timer on component unmount
  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [timer],
  );

  /**
   * This function handles the submission of a payment form.
   * It performs validation, handles payment errors, and processes successful payments.
   */
  const handleSubmit = async () => {
    // Trigger form validation
    const isValid = await trigger();

    // If form is not valid, return early
    if (!isValid) return;

    // If Stripe.js or Stripe Elements is not loaded, disable form submission
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Function to handle payment errors
    const handlePaymentError = async (paymentError: any) => {
      setIsReversedAfterApproval(false);
      const errorMessages = { error: "", subError: "" };
      if (paymentData.paymentGatewayProvider === "express") {
        // Handle payment errors specific to the "express" payment gateway
        // Fetch payment error information from the server
        try {
          const {
            data: { data },
          } = await fetchPaymentErrorInfo(
            params.locationId,
            paymentError.charge,
          );

          if (data.networkStatus === "reversed_after_approval") {
            // If the network status is "reversed_after_approval",
            // set the state to indicate that the payment was reversed after approval
            setIsReversedAfterApproval(true);
          }
          if (data.reason === "rule") {
            // If the reason for the error is a "rule",
            // retrieve the custom error message from PaymentRules based on the rule
            const customError = PaymentRules[data.rule];
            if (!_.isEmpty(customError)) {
              // If a custom error message is found,
              // set the error messages and return early
              errorMessages.error = customError.error1;
              errorMessages.subError = customError.error2;
              return setStripeError(errorMessages);
            }
          }

          // If no custom error message is found, set the payment error message
          errorMessages.error = paymentError.message;
          return setStripeError(errorMessages);
        } catch (err) {
          // Handle errors that occur during the retrieval of payment error info
          if (paymentError.message) {
            // If there is a payment error message, set it as the error message
            errorMessages.error = paymentError.message;
            return setStripeError(errorMessages);
          }
          // If no payment error message is available, set a generic error message
          setOpen(true);
          return setError(ERROR_UNEXPECTED_CHECK_CONNECTION);
        }
      } else {
        // Handle payment errors for payment gateways other than "express"
        if (paymentError.message) {
          // If there is a payment error message, set it as the error message
          errorMessages.error = paymentError.message;
          return setStripeError(errorMessages);
        }
        // If no payment error message is available, set a generic error message
        setOpen(true);
        return setError(ERROR_UNEXPECTED_CHECK_CONNECTION);
      }
    };

    // Get the CardElement from Stripe Elements
    const cardElement = elements.getElement(CardElement);

    if (cardElement) {
      // Confirm the card payment with Stripe
      const result = await stripe.confirmCardPayment(paymentData.secret, {
        payment_method: {
          card: cardElement,
        },
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        handlePaymentError(result.error);
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === "succeeded") {
          // FacebookPixel.purchase(cartItems, paymentAmount);
          // Show a success message to your customer
          setSuccess("Payment processed successfully");
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          try {
            // Attempt to complete the order by making a request to the server
            const res = await completeOrder(params.locationId, params.orderId);
            if (res.status === 200) {
              setShowSuccessModal(true);

              // Set a timeout to display the success modal for a few seconds
              const timeout = setTimeout(() => {
                setShowSuccessModal(false);

                // Redirect the user to the order status page
                window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${params.locationId}/order-status/${params.orderId}`;
              }, 3000);
              setTimer(timeout);
            } else {
              // If there is an error when completing the order, display an error message
              setOpen(true);
              setError(
                "Error occurred when completing order. Please check your connection.",
              );
            }
          } catch (error) {
            // Handle errors that occur during the order completion process
            const err: any = error as AxiosError;
            if (err.response.status === 409) {
              setShowSuccessModal(true);

              // Set a timeout to display the success modal for a few seconds
              const timeout = setTimeout(() => {
                setShowSuccessModal(false);

                // Redirect the user to the order status page
                window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${params.locationId}/order-status/${params.orderId}`;
              }, 3000);
              setTimer(timeout);
            } else {
              setOpen(true);
              if ((err as AxiosError<any>)?.response?.data?.message) {
                // If there is a specific error message in the response data, set it as the error message
                setError((err as AxiosError<any>)?.response?.data.message);
              } else {
                // If no specific error message is available, set a generic error message
                setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
              }
            }
          }
        }
      }
      return;
    }
  };

  const handleClose = (e: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <PaymentErrorAlertDialog
        error={stripeError.error}
        subError={stripeError.subError}
        open={!_.isEmpty(stripeError)}
        onClose={() => setStripeError({})}
        isReversedAfterApproval={isReversedAfterApproval}
      />
      <AlertDialog
        dialogTitle="Error"
        variant="error"
        open={open}
        handleClose={handleClose}
        handleCancel={handleClose}
      >
        <Typography variant="subtitle2" style={{ margin: "16px 0px" }}>
          {error}
        </Typography>
      </AlertDialog>
      <ContentContainer>
        <ErrorBoundary>
          
          {paymentData && (
            <GooglePlay
              paymentData={paymentData}
              setError={setError}
              setShowSuccessModal={setShowSuccessModal}
              setTimer={setTimer}
              setSuccess={setSuccess}
              orderInfo={orderInfo}
              setOpen={setOpen}
              locationName={locationName}
            />
          )}
        </ErrorBoundary>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth>
              {paymentData && (
                <CardSection
                  setPaymentInfo={setPaymentInfo}
                />
              )}
              <ButtonCommon
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  width: "100%",
                  fontWeight: "bold",
                }}
                disabled={!paymentData.entityId}
                variant="contained"
                onClick={handleSubmit}
              >
                <Typography style={{color: "white"}}>
                Pay
                </Typography>
              </ButtonCommon>
            </FormControl>
          </Grid>
        </Grid>
      </ContentContainer>
    </>
  );
};

export default CheckoutForm;
