import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      overflowWrap: "break-word",
      wordWrap: "break-word",
      border: `1px solid #DFE1E6`,
      borderRadius: "10px",
      [`& fieldset`]: {
        border: `1px solid #DFE1E6`,
        borderRadius: "10px",
        cursor: "pointer",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid #DFE1E6`,
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid #DFE1E6`,
      },
    },
  }),
);

/**
 * This is reusable text field component with custom styling.
 */
export interface TextfieldCommonProps {
  id?: any;
  name?: any;
  label?: any;
  disabled?: any;
  type?: any;
  inputRef?: any;
  helperText?: any;
  style?: any;
  value?: any;
  onChange?: any;
  inputProps?: any;
  error?: any;
  onKeyDown?: any;
  margin?: any;
  defaultValue?: any;
  multiline?: any;
  rows?: any;
  autoComplete?: any;
  placeholder?: any
}

const TextfieldCommon: React.FunctionComponent<TextfieldCommonProps> = ({
  id,
  name,
  label,
  disabled,
  inputRef,
  helperText,
  type,
  style,
  value,
  onChange,
  inputProps,
  error,
  onKeyDown,
  margin = "dense",
  defaultValue,
  multiline,
  rows,
  autoComplete,
  placeholder
}) => {
  const classes = useStyles();

  return (
    <TextField
      id={id}
      name={name}
      label={label}
      value={value}
      InputProps={inputProps}
      className={classes.textField}
      onChange={onChange}
      onKeyDown={onKeyDown}
      style={style}
      variant="outlined"
      error={error}
      disabled={disabled}
      size="small"
      type={"email"}
      fullWidth
      defaultValue={defaultValue}
      inputRef={inputRef}
      helperText={helperText}
      multiline={multiline}
      rows={rows}
      autoComplete={autoComplete}
      placeholder={placeholder}
      autoFocus
      inputProps={{
        autoComplete: 'true',
      }}
    />
  );
};

export default TextfieldCommon;
