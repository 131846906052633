import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CreditCardPayment from "../pages/CreditCardPayment";
import { createBrowserHistory } from "history";
import OrderInfo from "../pages/OrderInfo";
import OrderStatus from "../pages/OrderStatus";

const theme = createTheme({
  typography: {
    h1: {
      color: "#7a7a7a",
    },
    h2: {
      color: "#7a7a7a",
    },
    h3: {
      color: "#7a7a7a",
    },
    h4: {
      color: "#7a7a7a",
    },
    h5: {
      color: "#7a7a7a",
    },
    h6: {
      color: "#7a7a7a",
    },
    body1: {
      color: "#7a7a7a",
    },
    body2: {
      color: "#7a7a7a",
    },
    caption: {
      color: "#7a7a7a",
    },
    subtitle2: {
      color: "#7a7a7a",
    }
  },
});

// Create a browser history object for React Router
const history: any = createBrowserHistory();

function App() {
  return (
    // Wrap the app in a Material-UI theme provider
    <ThemeProvider theme={theme}>
      {/* Set up React Router with the browser history */}
      <Router history={history}>
        {/* Define the routes using React Router */}
        <Switch>
          <Route
            key="location"
            path="/"
            render={() => {
              return (
                <>
                  {/* Route for displaying order information */}
                  <Route
                    key="order-info"
                    exact
                    path="/l/:shortId"
                    component={OrderInfo}
                  />
                  {/* Route for credit card payment */}
                  <Route
                    key="order-status"
                    exact
                    path="/location/:locationId/order/:orderId"
                    component={CreditCardPayment}
                  />
                  {/* Route for order status */}
                  <Route
                    key="order-status"
                    exact
                    path="/location/:locationId/order-status/:orderId"
                    component={OrderStatus}
                  />
                </>
              );
            }}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
