import React, { useImperativeHandle, useRef } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  CardElement,
  PaymentElement,
} from "@stripe/react-stripe-js";
import TextFieldDefault from "../../../common/tesxfields/TextFieldDefault";
import "./CardSectionStyles.css";
import { Grid, Theme, Typography, makeStyles } from "@material-ui/core";
import TextfieldCommon from "../../../common/tesxfields/TextfieldCommon";
import CardCommon from "../../../common/card/CardCommon";
import visa_icon from "../../../assets/images/visa_icon.png";
import master_icon from "../../../assets/images/master_icon.png";
import TextfieldCommonBorderNone from "../../../common/tesxfields/TextfieldCommonBorderNone";

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      marginBottom: "4px",
    },
  },
}));

// Custom input component for Stripe integration
const StripeInput = ({
  component: Component,
  inputRef,
  setPaymentInfo,
  ...props
}: any) => {
  const elementRef: any = useRef();

  // Expose focus method of the input element using useImperativeHandle
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <Component
      onReady={(element: any) => (elementRef.current = element)}
      {...props}
      onChange={(e: any) => {
        setPaymentInfo((prevState: any) => ({
          ...prevState,
          // postalCode: e.value.postalCode,
        }));
      }}
    />
  );
};

// Custom input component for Stripe integration
const StripeInputCardNumber = ({
  component: Component,
  inputRef,
  setPaymentInfo,
  ...props
}: any) => {
  const elementRef: any = useRef();

  // Expose focus method of the input element using useImperativeHandle
  useImperativeHandle(inputRef, () => ({
    focus: () => elementRef.current.focus,
  }));
  return (
    <>
      <Component
        onReady={(element: any) => (elementRef.current = element)}
        {...props}
        onChange={(e: any) => {
          setPaymentInfo((prevState: any) => ({
            ...prevState,
            // postalCode: e.value.postalCode,
          }));
        }}
      />
      <img src={visa_icon} style={{ height: "32px" }} />
      <img src={master_icon} style={{ height: "32px" }} />
    </>
  );
};

// Define the props interface for the CardSection component
export interface PaymentCompleteProps {
  setPaymentInfo: any;
}

/**
 * This component provides a section for entering card details using Stripe integration.
 * It uses the CardElement component from the @stripe/react-stripe-js library and a custom StripeInput component.
 */
const CardSection: React.FunctionComponent<PaymentCompleteProps> = ({
  setPaymentInfo,
}) => {
  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
          lineHeight: "2",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
      hidePostalCode: true,
    },
  };
  const handleChangeEmail = (e: any) => {
    setPaymentInfo((prevState: any) => ({
      ...prevState,
      postalCode: e.target.value,
    }));
  };

  const classes = useStyles();
  return (
    <>
      <Typography className={classes.text} style={{ fontWeight: "bold" }}>
        Card information
      </Typography>
      {/* <TextFieldDefault
        inputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardElement,
            options: CARD_ELEMENT_OPTIONS,
            setPaymentInfo,
          },
        }}
      /> */}
      <TextFieldDefault
        inputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component: CardElement,
            options: CARD_ELEMENT_OPTIONS,
            setPaymentInfo,
          },
        }}
      />
      {/* <CardCommon>
        <Grid container>
          <Grid item xs={12} style={{ borderBottom: `1px solid #DFE1E6` }}>
            <TextFieldDefault
              inputProps={{
                inputComponent: StripeInputCardNumber,
                inputProps: {
                  component: CardNumberElement,
                  options: CARD_ELEMENT_OPTIONS,
                  setPaymentInfo,
                },
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ borderRight: `1px solid #DFE1E6` }}>
            <TextFieldDefault
              inputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardExpiryElement,
                  options: CARD_ELEMENT_OPTIONS,
                  setPaymentInfo,
                },
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ borderRight: `1px solid #DFE1E6` }}>
            <TextFieldDefault
              inputProps={{
                inputComponent: StripeInput,
                inputProps: {
                  component: CardCvcElement,
                  options: CARD_ELEMENT_OPTIONS,
                  setPaymentInfo,
                },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextfieldCommonBorderNone
              value={paymentInfo.postalCode}
              placeholder="Zip"
              onChange={handleChangeEmail}
              style={
                stripeError && stripeError.toLowerCase().includes("postal code")
                  ? { color: "red" }
                  : { color: "#7a7a7a" }
              }
            />
          </Grid>
        </Grid>
      </CardCommon> */}
    </>
  );
};

export default CardSection;
