/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import {
  fetchAllOrderInfoNotShortId,
  updateAllOrderInfo,
} from "../../services/OrderServices";
import _ from "lodash";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../utils/utils";
import { AxiosError } from "axios";
import { fetchAllLocationInfo } from "../../services/LocationServices";
import Loading from "./Loading";
import OrderSuccessAlertDialog from "../../common/dialogs/OrderSuccessAlertDialog";
import AlertDialog from "../../common/dialogs/AlertDialog";
import { Typography } from "@material-ui/core";
import { handleEmailValidate } from "../../utils/HandleEmailValidate";

// Define TypeScript types/interfaces
interface OrderInfo {
  id: string;
  customer: {
    email: string;
  };
  shortId: string;
}

interface PaymentData {
  entityId: string;
  publicKey: string;
}

/**
 * CreditCardPayment component handles the credit card payment process.
 * It retrieves order information, updates order details, and displays the payment form.
 * It also handles form submission and shows loading states and alert messages.
 */
const CreditCardPayment: React.FunctionComponent = () => {
  const [email, setEmail] = useState("");
  const [locationName, setLocationName] = useState("");
  const [address, setAddress] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [paymentAmount, setPaymentAmount] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [timer, setTimer] = useState<null | number>(null);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [paymentData, setPaymentData] = useState<PaymentData>({
    entityId: "",
    publicKey: "",
  });
  const [orderInfo, setOrderInfo] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const match: any = useRouteMatch();

  // Retrieve the orderId and locationId from the match params
  const { orderId, locationId } = match.params;

  /**
   * Updates the order information with the order information.
   */
  const updateOrderInfo = async (orderInfo: any) => {
    setIsLoadingButton(true);
    // FacebookPixel.addPaymentInfo(cartItems, orderInfo.paymentAmount, "CARD");
    try {
      // Make an API call to update the order information
      const res: any = await updateAllOrderInfo(locationId, orderId, orderInfo);
      setPaymentData(res.data.data);
      setOrderInfo(orderInfo);
      if (res.status === 200) {
        // Show the success modal
        // Set the loading button state to false
        setIsLoadingButton(false);
        setIsBack(false);
      }
      // Set the loading state to false
      setIsLoading(false);
      setIsValidated(false);
    } catch (error) {
      const err: any = error as AxiosError;
      if (err.response.status === 409) {
        // Call the handleGetOrderInfoNotUsingShortId function if the orderInfo does not have a shortId property
        handleGetOrderInfoNotUsingShortId();
      } else {
        if (err?.response?.data?.message) {
          // Set the alert message based on the error response message
          if (err?.response?.data?.message === "Order already processed") {
            return (window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${locationId}/order-status/${orderId}`);
          } else {
            setIsLoading(false);
            setOpen(true);
            setIsLoadingButton(false);
            return setAlertMessage(err?.response?.data?.message);
          }
        }
        setIsLoading(false);
        setOpen(true);
        setIsLoadingButton(false);
        // Set a default error message if no custom error message is available
        return setAlertMessage(
          "Error placing the order! Please restart the order or please contact the restaurant if the issue persists",
        );
      }
      setIsValidated(false);
    }
  };

  /**
   * Handles form submission
   */
  const handleSubmit = () => {
    // Create a deep copy of the orderInfo object using lodash cloneDeep method
    const cloneOrderInfo = _.cloneDeep(orderInfo);
    setIsValidated(true);
    // Update the email property of the customer object in the cloned orderInfo
    cloneOrderInfo.customer.email = email;
    // Call the updateOrderInfo function with the cloned orderInfo as the parameter
    if(_.isEmpty(handleEmailValidate(true, email))){
      updateOrderInfo(cloneOrderInfo);

    }
  };

  /**
   * Handles email input change
   */
  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  /**
   * Retrieves the order information using orderId and locationId.
   * This function is used when shortId is not available.
   */
  const handleGetOrderInfoNotUsingShortId = async () => {
    try {
      // Fetch the order information using the locationId and orderId
      const res = await fetchAllOrderInfoNotShortId(locationId, orderId);
      setOrderInfo(res.data.data);

      // Destructure the paymentAmount and customer properties from the fetched data
      const { paymentAmount } = res.data.data;
      // Create a deep copy of the orderInfo object using lodash cloneDeep method
      const cloneOrderInfo = _.cloneDeep(res.data.data);
      if (email) {
        // Update the email property of the customer object in the cloned orderInfo
        cloneOrderInfo.customer.email = email;
      }
      setPaymentAmount(parseFloat(paymentAmount).toFixed(2));
      if (
        !_.isEmpty(cloneOrderInfo.customer) &&
        !_.isEmpty(cloneOrderInfo.customer.email)
      ) {
        // Call the updateOrderInfo function with the fetched order information
        updateOrderInfo(cloneOrderInfo);
        // Set the paymentAmount state with the parsed and formatted payment amount
        setEmail(cloneOrderInfo.customer.email);
        if (cloneOrderInfo.customer.email) {
          // Set the isBack state to false if the customer's email is available
          setIsBack(false);
        }
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false);
      setOpen(true);
      setIsLoadingButton(false);
      const err: any = error as AxiosError;
      if (err?.response?.data?.message) {
        // Set the alert message based on the error response message
        return setAlertMessage(err?.response?.data?.message);
      }
      // Set a default error message if no custom error message is available
      setAlertMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Retrieves the location information based on the locationId.
   * It sets the location name and stores it in a cookie.
   */
  const getLocationInfo = async () => {
    try {
      // Fetch the location information using the locationId
      const res = await fetchAllLocationInfo(locationId);
      // Destructure the businessDisplayName property from the fetched data
      const {
        businessDisplayName,
        addressLine1,
        addressLine2,
        city,
        postcode,
        contactNo,
      } = res.data.data;
      setLocationName(businessDisplayName);
      setContactNo(contactNo);
      const address = `${addressLine1.trimEnd()}${
        addressLine2 ? `, ${addressLine2}` : ""
      }${city ? `, ${city}` : ""}${postcode ? `, ${postcode}` : ""}`;
      setAddress(address);
    } catch (err) {
      setOpen(true);
      // Set the alert message to display an unexpected error if an error occurs
      setAlertMessage(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  useEffect(() => {
    // Call the handleInitialLoad function to handle the initial loading of data
    handleGetOrderInfoNotUsingShortId();
    getLocationInfo();
  }, []);
  const handleClose = (e: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <AlertDialog
        dialogTitle="Error"
        variant="error"
        open={open}
        handleClose={handleClose}
        handleCancel={handleClose}
      >
        <Typography variant="subtitle2" style={{ margin: "16px 0px" }}>
          {alertMessage}
        </Typography>
      </AlertDialog>
      <OrderSuccessAlertDialog
        open={showSuccessModal}
        handleClose={() => setShowSuccessModal(false)}
      />
      <Loading
        locationName={locationName}
        paymentAmount={paymentAmount}
        setIsBack={setIsBack}
        isBack={isBack}
        paymentData={paymentData}
        email={email}
        isValidated={isValidated}
        handleChangeEmail={handleChangeEmail}
        handleSubmit={handleSubmit}
        setShowSuccessModal={setShowSuccessModal}
        setTimer={setTimer}
        timer={timer}
        orderInfo={orderInfo}
        isLoadingButton={isLoadingButton}
        isLoading={isLoading}
        contactNo={contactNo}
        address={address}
      />
    </>
  );
};

export default CreditCardPayment;
