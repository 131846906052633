import { CircularProgress } from "@material-ui/core";
import _ from "lodash";
import React from "react";

const handleLoadingMethod = () => (
  <CircularProgress color="secondary" size={50} />
);

/* To display a loader until data is retrieved from the API call. */
const WithLoading = (Component: any) => (props: any) => {
  if (!props.isLoading) {
    return <Component {...props} />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginTop: "16%",
        }}
      >
        {handleLoadingMethod()}
      </div>
    );
  }
};

export default WithLoading;
