/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { fetchAllOrderInfo } from "../../services/OrderServices";
import { useRouteMatch } from "react-router-dom";
import { fetchAllLocationInfo } from "../../services/LocationServices";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../utils/utils";
import AlertDialog from "../../common/dialogs/AlertDialog";
import { CircularProgress, Typography } from "@material-ui/core";
import { AxiosError } from "axios";

/**
 * OrderInfo component retrieves and sets order information and location name.
 * It makes API calls to fetch order and location details, and sets cookies and local storage.
 * It also displays an error message using the AlertMessage component.
 */
const OrderInfo: React.FunctionComponent = () => {
  const [error, setError] = useState<any>("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const match: any = useRouteMatch();

  /**
   * Retrieves the location information based on the location ID and sets the location name cookie.
   * Redirects to the credit card payment page with the location and order ID.
   * @param locationId The ID of the location
   * @param id The ID of the order
   */
  const getLocationInfo = async (locationId: String, id: String) => {
    try {
      const res: any = await fetchAllLocationInfo(locationId);
      window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/location/${locationId}/order/${id}`;
    } catch (err) {
      setIsLoading(false);
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Retrieves the order information based on the short ID.
   * Calls the getLocationInfo function to fetch location details and set cookies.
   * Stores the order information in local storage.
   */
  const getOrderInfo = async () => {
    try {
      const shortId = match.params.shortId;
      const res: any = await fetchAllOrderInfo(shortId);
      const { locationId, id } = res.data.data;
      getLocationInfo(locationId, id);
    } catch (error) {
      setOpen(true);
      setIsLoading(false);
      const err: any = error as AxiosError;
      if (err?.response?.data?.message) {
        // Set the alert message based on the error response message
        return setError(err?.response?.data?.message);
      }
      // Set a default error message if no custom error message is available
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /**
   * Fetch order information and perform necessary actions on component mount
   * */
  useEffect(() => {
    getOrderInfo();
  }, []);
  const handleClose = (e: any, reason: any) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
  };
  // Render an error message using the AlertMessage component
  return (
    <>
      <AlertDialog
        dialogTitle="Error"
        variant="error"
        open={open}
        handleClose={handleClose}
        handleCancel={handleClose}
      >
        <Typography variant="subtitle2" style={{ margin: "16px 0px" }}>
          {error}
        </Typography>
      </AlertDialog>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginTop: "16%",
          }}
        >
          <CircularProgress color="secondary" size={50} />
        </div>
      )}
    </>
  );
};

export default OrderInfo;
