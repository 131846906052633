import React, { ReactNode, ErrorInfo, ReactElement } from "react";

interface ErrorBoundaryProps {
  children: ReactNode;
}

/**
 * ErrorBoundary is a component that catches errors in its child components.
 * It provides an error fallback UI and logs the error to the console.
 */
const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = React.useState(false);

  // Reset the error state when the component mounts
  React.useEffect(() => {
    setHasError(false);
  }, []);

  // Error handler function called when an error is caught
  const handleCatchError = (error: Error, errorInfo: ErrorInfo) => {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  };

  if (hasError) {
    // You can render any custom fallback UI
    return null;
  }

  return (
    <React.Fragment>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child as ReactElement, {
            onError: handleCatchError,
          });
        }
        return child;
      })}
    </React.Fragment>
  );
};

export default ErrorBoundary;
