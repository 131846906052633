export const isMobileView = () =>
  window.matchMedia("(max-width: 900px)").matches;
export const isEmbeddedWindow = () => !(window.top === window.self);

export const ERROR_MESSAGE_UNEXPECTED_ERROR =
  "Unexpected error! Please check your connection";

export const EMAIL_PATTERN =
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;

export const PaymentRules: any = {
  ssr_1JRuZbEu0XseDNv1Nqb3j6s9: {
    error1:
      "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBI64Eu0XseDNv17FA1vVKR: {
    error:
      "Transaction Declined.  Non UK cards are not accepted. Please try again with a UK registered debit or credit card.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBIs6Eu0XseDNv1pUTeFlhf: {
    error1: "Transaction Declined. Your region seems to be outside of the UK.",
    error2: "Please try Apple or Google Pay.",
  },

  ssr_1JBI8DEu0XseDNv1DGUyCKyx: {
    error1:
      "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay.",
  },

  ssr_1JBI5PEu0XseDNv1F5mK9e2e: {
    error1:
      "Transaction Declined. Postcode provided does not match the postcode on your bank records. Please try again with the correct postcode.",
    error2: "Alternatively please try Apple or Google Pay",
  },

  ssr_1JBIYEEu0XseDNv1jOoWfr9y: {
    error1:
      "Transaction Declined. Invalid CVC number provided. Please try again with the correct CVC.",
    error2: "Alternatively please try Apple or Google Pay.",
  },
};

export const ERROR_UNEXPECTED_CHECK_CONNECTION =
  "Unexpected Error. Please check your connection.";

export const getCompleteOrderErrorMessage = (
  restaurantName: any,
  restaurantContact: any,
) =>
  `Something went wrong but your order might have gone through. Please contact the ${restaurantName} on ${restaurantContact} to confirm.`;
